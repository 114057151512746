.wrapCart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 110px;
    margin-bottom: 0px;
    @media(max-width: 520px){
        margin-top: 72px;
    }
    .wrapMainPageLink {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 5px;
        @media(max-width: 520px){
            margin-top: 2px;
            margin-right: 30px;
        }
        .homePic {
            font-size: 24px;
            color: #474A51;
            margin-right: 10px;
            margin-bottom: 3px;
            @media(max-width: 520px){
                font-size: 20px;
            }
        }
        .link {
            margin-right: 40px;
            text-decoration: none;
            border-bottom: 3px solid #474A51;
            font-size: 22px;
            font-weight: 700;
            color: #474A51;
            @media(max-width: 520px){
                margin-right: 18px;
                font-size: 18px;
                border-bottom: 2px solid #474A51;
            }
        }
    }
    .title {
        font-size: 30px;
        font-weight: 700;
        color: rgba(241,133,0, 0.9);
        margin-top: 8px;
        margin-bottom: 5px;
        @media(max-width: 520px){
            font-size: 24px;
        }
    }
    .cart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 92%;
        height: 100%;
        border-bottom: 3px solid gray;
        //border-radius: 12px;
        margin-top: 10px;
        padding-bottom: 5px;
        .wrapProduct {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 35%;
            height: 100%;
            margin-left: 10px;
            margin-bottom: 10px;
            .product {
                width: 100%;
                height: auto;
                border-radius: 20px;
            }
            > span {
                font-size: 20px;
                font-weight: 600;
                color: gray;
                @media(max-width: 520px){
                    font-size: 18px;
                }
            }
        }
        .wrapCostBlock {
            width: 25%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            @media(max-width: 520px){
                width: 35%;
            }
            .wrapCost {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 36px;
                background-color: yellowgreen;
                margin-bottom: 14px;
                border-radius: 8px;
                .cost {
                    font-size: 19px;
                    font-weight: 500;
                    color: white;
                }
            }
            .wrapAmount {
                width: 100%;
                height: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                .amount {
                    font-size: 19px;
                    font-weight: 500;
                    color: gray;
                }
            }
            .wrapCounter {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 34px;
                margin-top: 14px;
                .minus {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40%;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    background-color: gray;
                    font-size: 32px;
                    color: white;
                    font-weight: 500;
                    cursor: pointer;
                    &:active {
                        transform: translateY(3px);
                    }
                }
                .plus {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40%;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    background-color: rgba(241,133,0, 0.9);
                    font-size: 32px;
                    color: white;
                    font-weight: 500;
                    cursor: pointer;
                    &:active {
                        transform: translateY(3px);
                    }
                }
            }
        }
        .delete {
            font-size: 40px;
            color: rgb(208,25,9);
            margin-right: 10px;
            margin-left: 10px;
            cursor: pointer;
            &:active {
                transform: translateY(3px);
            }
            @media(max-width: 520px){
                font-size: 30px;
            }
        }
    }

    .wrapInput {
        display: flex;
        width: 92%;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 22px;
        > span {
            font-size: 18px;
            font-weight: 600;
            color: gray;
        }
        .promoCodeBlock {
            display: flex;
            width: 100%;
            .promoCodeBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22%;
                height: 40px;
                background-color: rgba(241,133,0, 0.9);
                border-radius: 8px;
                font-size: 20px;
                font-weight: 500;
                color: white;
                &:active {
                    transform: translateY(3px);
                }
                @media(max-width: 520px){
                    width: 38%;
                }
            }
        }
        .input {
            border: none;
            outline: none;
            width: 56%;
            font-size: 20px;
            border-bottom: 3px solid gray;
            background-color: transparent;
            padding-left: 10px;
            color: gray;
            font-weight: 600;
            margin-right: 6%;
        }
        .wrapRadio {
            display: flex;
            margin-top: 14px;
            > span {
                font-size: 18px;
                font-weight: 600;
                color: gray;
            }
            .radio {
                appearance: none; 
                width: 20px;
                height: 20px;
                margin-right: 10px;
                border-radius: 50px;
                border: 3px solid gray;
            }
        }
        .textarea {
            width: 56%;
            height: 60px;
            margin-top: 14px;
            border: 3px solid gray;
            border-radius: 12px;
            padding-left: 10px;
            font-size: 20px;
            color: gray;
            font-weight: 600;
            @media(max-width: 520px){
                width: 100%;
            }
        }
    }

    .wrapAllCost {
        width: 92%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 22px;
        > span {
            font-size: 18px;
            font-weight: 600;
            color: gray;
        }
        .wrapAllCost {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 240px;
            height: 36px;
            background-color: yellowgreen;
            margin: 0px;
            margin-left: 10px;
            border-radius: 8px;
            @media(max-width: 520px){
                width: 140px;
            }
            .cost {
                font-size: 19px;
                font-weight: 500;
                color: white;
            }
        }
    }
    .wrapOrder {
        width: 280px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 22px;
        background-color: rgba(241,133,0, 0.9);
        margin-bottom: 24px;
        border-radius: 12px;
        &:active {
            transform: translateY(6px);
        }
        @media(max-width: 520px){
            width: 240px;
        }
        .sendText {
            font-size: 20px;
            font-weight: 500;
            color: white;
            text-decoration: none;
        }
    }
    .wrapCalls {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 10px;
        @media(max-width: 520px){
            margin-top: 15px;
        }
        .call {
            margin-left: 10px;
            margin-right: 10px;
            width: 60px;
            height: 60px;
            @media(max-width: 520px){
                width: 40px;
                height: 40px;
            }
        }
    }
}