.wrapDishes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    @media(max-width: 520px){
        margin-top: 72px;
    }
    .wrapMainPageLink {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 60px;
        margin-top: 5px;
        @media(max-width: 520px){
            margin-top: 2px;
            margin-right: 30px;
        }
        .homePic {
            font-size: 24px;
            color: #474A51;
            margin-right: 10px;
            margin-bottom: 3px;
            @media(max-width: 520px){
                font-size: 20px;
            }
        }
        .link {
            text-decoration: none;
            border-bottom: 3px solid #474A51;
            font-size: 22px;
            font-weight: 700;
            color: #474A51;
            @media(max-width: 520px){
                font-size: 18px;
                border-bottom: 2px solid #474A51;
            }
        }
    }
    .mainTitle {
        font-size: 30px;
        font-weight: 700;
        color: rgba(241,133,0, 0.9);
        margin-top: 18px;
        margin-bottom: 5px;
        @media(max-width: 520px){
            font-size: 24px;
        }
    }
    .pageLink {
        height: 34px;
        @media(max-width: 520px){
            height: 27px;
        }
    }
    .blockDishes {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        cursor: pointer;
        @media(max-width: 520px){
            width: 98%;
        }
        .dishes {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @media(max-width: 520px){
                margin: 10px;
                flex-direction: column;
                justify-content: space-between;
                width: 90%;
                //border: 4px solid rgb(208,25,9);
                border-radius: 20px;
                box-shadow: 0 2px 6px 4px rgba(0,0,0,0.5);
            }
            .picDishes {
                width: 20%;
                height: auto;
                margin-left: 30px;
                border-radius: 32px;
                margin-top: 12px;
                @media(max-width: 520px){
                    margin-left: 0px;
                    width: 88%;
                    height: auto;
                }
            }
            .wrapDiscriptions {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 35%;
                padding-left: 25px;
                padding-right: 20px;
                @media(max-width: 520px){
                    min-width: 90%;
                }
                .title {
                    font-size: 24px;
                    font-weight: 700;
                    color: rgba(241,133,0, 0.9);
                    margin-bottom: 10px;
                    text-align: center;
                    @media(max-width: 520px){
                        font-size: 22px;
                        margin-bottom: 5px;
                    }
                }
                .discriptions {
                    font-size: 22px;
                    font-weight: 600;
                    text-align: center;
                    color:  #474A51;
                    margin-top: 10px;
                    @media(max-width: 520px){
                        font-size: 15px;
                        margin-top: 0px;
                    }
                }
            }
            .wrapBuy {
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                @media(max-width: 520px){
                    width: 100%;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
                .cost {
                    font-size: 20px;
                    font-weight: 600;
                    color:  #474A51;
                    margin-right: 18px;
                    @media(max-width: 520px){
                        font-size: 15px;
                        margin-right: 4px;
                    }
                }
                .basketDishes {
                    font-size: 44px;
                    margin-right: 30px;
                    cursor: pointer;
                    @media(max-width: 520px){
                        font-size: 19px;
                        margin-right: 0cm;
                    }
                    &:active {
                        transform: translateY(6px);
                    }
                }
            }
            .wrapBtn {
                width: 20%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 18px;
                @media(max-width: 520px){
                    width: 100%;
                }
                .btn {
                    width: 80%;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(241,133,0, 0.9);
                    border-radius: 12px;
                    box-shadow: 0 2px 3px 2px rgba(0,0,0,0.5);
                    text-align: center;
                    @media(max-width: 520px){
                        width: 72%;
                        height: 52px;
                    }
                    &:active {
                        transform: translateY(4px);
                    }
                    > span {
                        font-size: 20px;
                        font-weight: 500;
                        color: white;
                    }
                }
            }
        }
    }
    
}
.wrapAddedCart {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 260px;
    height: 190px;
    padding: 15px;
    border-radius: 24px;
    box-shadow: 0 4px 6px 4px rgba(0,0,0,0.5);
    >span {
        font-size: 28px;
        line-height: 38px;
        font-weight: 600;
        color: yellowgreen;
    }
}